import { Link } from "gatsby"

import React from "react"

import styles from "./related.module.scss"

interface Props {
  caseStudies: Node[]
  currentSlug: string
}
interface Node {
  node: CaseStudy
}
interface CaseStudy {
  slug: string
  highlightColour: {
    red: string
    green: string
    blue: string
    alpha: string
  }
  title: string
  titleHighlight: string
  preview: {
    image: {
      url: string
    }
    colour: {
      red: string
      green: string
      blue: string
      alpha: string
    }
    highlightColour: {
      red: string
      green: string
      blue: string
      alpha: string
    }
  }
}

export default ({ caseStudies, currentSlug }: Props) => {
  const nextIndex = caseStudies.findIndex((c) => c.node.slug === currentSlug)
  const itemIndex = nextIndex + 1 >= caseStudies.length ? 0 : nextIndex + 1
  const caseStudy = caseStudies[itemIndex].node
  return (
    <Link
      to={`/case-studies/${caseStudy.slug}`}
      className={styles.columns}
      style={{
        backgroundColor: `rgba(${caseStudy.preview[0].colour.red},${caseStudy.preview[0].colour.green},${caseStudy.preview[0].colour.blue},${caseStudy.preview[0].colour.alpha})`,
      }}
      data-hover="Read more"
      data-hover-background="#000"
    >
      <div className={styles.content}>
        <h3 className="display5 text-uppercase">Next up</h3>
        <h3 className={styles.title}>
          <span
            dangerouslySetInnerHTML={{
              __html: caseStudy.title,
            }}
          />
          <br />
          <span
            style={{
              color: `rgba(${caseStudy.preview[0].highlightColour.red},${caseStudy.preview[0].highlightColour.green},${caseStudy.preview[0].highlightColour.blue},${caseStudy.preview[0].highlightColour.alpha})`,
            }}
            dangerouslySetInnerHTML={{
              __html: caseStudy.titleHighlight,
            }}
          />
        </h3>
      </div>
      <div className={styles.image}>
        <picture>
          <source
            type="image/webp"
            media="(min-width: 1024px)"
            srcSet={`${caseStudy.preview[0].image.url}?w=1500&h=1500&fm=webp`}
          />
          <source
            media="(min-width: 1024px)"
            srcSet={`${caseStudy.preview[0].image.url}?w=1500&h=1500`}
          />
          <source
            type="image/webp"
            media="(min-width: 768px)"
            srcSet={`${caseStudy.preview[0].image.url}?w=1000&h=1000&fm=webp`}
          />
          <source
            media="(min-width: 768px)"
            srcSet={`${caseStudy.preview[0].image.url}?w=800&h=800`}
          />
          <source
            type="image/webp"
            srcSet={`${caseStudy.preview[0].image.url}?w=800&h=800&fm=webp`}
          />
          <img src={`${caseStudy.preview[0].image.url}?w=800&h=800`} />
        </picture>
      </div>
    </Link>
  )
}
